import { Stack, Button } from "@mui/material";
import React, { useState } from "react";
import LoadingButtonIcon from "./LoadingButtonIcon";
import { i18n } from "../translate/i18n";
import CustomIcon from "../MUICustomComponents/CustomIcon";
import { GetApp } from "@mui/icons-material";
import { paletteObject } from "../theme/foundations/palette";
import { changeToHTTPS } from "../infrastructure/utils/StringUtils";
import Axios from "axios";
import NewToastComponent from "./NewToastComponent";
import { buildURLWithParams } from "../infrastructure/utils/BuildURLWithParams";

type Props = {
	exportFunction: Function;
	params: Object;
};

enum RuntimeStatus {
	Completed = "Completed",
	Running = "Running",
	Failed = "Failed",
	Pending = "Pending",
}

export default function ExportButton({ exportFunction, params }: Props) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const btnIconStyles = { mt: "3px", mr: 1 };

	const exportFile = async (): Promise<void> => {
		setIsLoading(true);

		try {
			const queryParams = buildURLWithParams(params);
			const response = await exportFunction(queryParams);
			const statusQueryGetUri = changeToHTTPS(response.statusQueryGetUri);
			const exportInterval = setInterval(async () => {
				const resp = await Axios.get(statusQueryGetUri);
				const runtimeStatus = resp.data.runtimeStatus;

				if (runtimeStatus === RuntimeStatus.Failed) {
					clearInterval(exportInterval);
					NewToastComponent({
						status: "error",
						title: i18n.t("components.ExportButton.Error"),
						message: i18n.t("errorMessages.TryAgainLater"),
					});
					setIsLoading(false);
				}

				if (runtimeStatus === RuntimeStatus.Completed) {
					clearInterval(exportInterval);
					window.open(resp.data.output, "_blank");
					NewToastComponent({
						status: "success",
						title: i18n.t("components.ExportButton.Success"),
					});
					setIsLoading(false);
				}
			}, 4000);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoading(false);
		}
	};

	return (
		<Button
			variant="outlined"
			color="light"
			disabled={isLoading}
			onClick={exportFile}
		>
			<Stack
				direction="row"
				alignItems="center"
				color={paletteObject.blueish20.main}
			>
				{isLoading ? (
					<>
						<LoadingButtonIcon sx={btnIconStyles} />
						{i18n.t("components.ExportButton.Processing").toString()}
					</>
				) : (
					<>
						<CustomIcon
							icon={<GetApp />}
							variant="default"
							sx={btnIconStyles}
						/>
						{i18n.t("components.ExportButton.Action").toString()}
					</>
				)}
			</Stack>
		</Button>
	);
}
