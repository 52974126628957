import { useEffect, useState } from "react";
import { troqueCommerceApi } from "../../../infrastructure/api/external/TroqueCommerce";
import { TCOrder } from "../../../domain/models/troquecommerce/TCOrder";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { useParams } from "react-router-dom";
import { TCItem } from "../../../domain/models/troquecommerce/TCItem";
import { ItemModel } from "../../../domain/models/commerce/ItemModel";
import { EROrderAsSimplifiedOrderModel } from "./components/ERItems";

export default function ERDetailsService() {
	const routeParams = useParams();
	const [currentTab, setCurrentTab] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [erOrder, setEROrder] = useState<TCOrder | null>(null);
	const [simplifiedOrderModel, setSimplifiedOrderModel] =
		useState<EROrderAsSimplifiedOrderModel | null>(null);

	useEffect(() => {
		setIsLoading(true);
		getEROrderById(routeParams.id);
	}, []);

	const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
		setCurrentTab(newValue);
	};

	const getEROrderById = async (erOrderId: string): Promise<void> => {
		try {
			const response: TCOrder = await troqueCommerceApi.getTCOrderById(
				erOrderId
			);
			const newSimplifiedOrderModel =
				mapEROrderToSimplifiedOrderModel(response);
			setEROrder(response);
			setSimplifiedOrderModel(newSimplifiedOrderModel);
			setIsLoading(false);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoading(false);
		}
	};

	const mapEROrderToSimplifiedOrderModel = (
		erOrder: TCOrder
	): EROrderAsSimplifiedOrderModel => {
		const itemQuantity: number = erOrder.items.reduce(
			(acc, currentValue) => acc + currentValue.quantity,
			0
		);

		const orderModelItems = mapERItemsToOrderModelItems(erOrder.items);
		const newOrderModel: EROrderAsSimplifiedOrderModel = {
			orderCode: erOrder.ecommerce_number,
			isDelivery: false, // Not being used
			estimateDate: erOrder.tracking?.expected_delivery_date,
			buyer: {
				fullName: erOrder.client.name,
				phoneNumber: erOrder.client.phone,
			},
			itemQuantity: itemQuantity,
			totalAmount: erOrder.price,
			items: orderModelItems,
		};
		return newOrderModel;
	};

	const mapERItemsToOrderModelItems = (items: TCItem[]): ItemModel[] => {
		const newItems: ItemModel[] = items.map(
			(item: TCItem): ItemModel => ({
				id: item.ecommerce_product_id,
				ean: item.ean,
				name: item.description,
				productCode: item.ecommerce_product_id,
				skuCode: item.sku,
				quantity: item.quantity,
			})
		);
		return newItems;
	};

	return {
		isLoading,
		erOrder,
		simplifiedOrderModel,
		routeParams,
		currentTab,
		handleTabChange,
	};
}
