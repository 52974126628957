import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ExchangeReturnList from "./ExchangeReturnList/ExchangeReturnList";
import ERDetails from "./ERDetails/ERDetails";

export default function ExchangeAndReturn() {
	return (
		<div className="container dash-content">
			<Switch>
				<Route
					path="/exchangeandreturn"
					exact
					children={<Redirect to="/exchangeandreturn/1" />}
				/>
				<Route
					path="/exchangeandreturn/:page"
					exact
					children={<ExchangeReturnList />}
				/>
				<Route path="/exchangeandreturn/:page/:id" children={<ERDetails />} />
			</Switch>
		</div>
	);
}
