import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import List from "../../components/List/List";
import Empty from "../../components/Empty";
import ContentLoader from "react-content-loader";
import FilterOrdersModal from "./components/OrderModal/FilterOrdersModal";
import { OrderFilterStatus } from "./enums/OrderFilterStatus";
import { ShippingMethod } from "./enums/ShippingMethod";
import { buildURLWithParams } from "../../infrastructure/utils/BuildURLWithParams";
import OrderListService from "./OrderListService";
import OrderFiltersLayout from "../../components/orders/OrderFiltersLayout/OrderFiltersLayout";
import { DateType } from "../../domain/enums/DateType";
import OrderTableColumns, {
	conditionalRowStyles,
	overwriteOrderStyles,
} from "../../components/OrderTableColumns/OrderTableColumns";
import { insightsLegacyApi } from "../../infrastructure/api/InsightsApi";
import ExportButton from "../../components/ExportButton";

export default function OrdersList() {
	const {
		loading,
		setLoading,
		currentSeller,
		setCurrentSeller,
		ordersList,
		maxCount,
		orderFilters,
		modality,
		setModality,
		setOrderFilters,
		// Functions
		changePage,
		changeRowsPerPage,
		updateFilters,
		getOrders,
		openModal,
	} = OrderListService();

	const history = useHistory();
	const [filtered, setFiltered] = useState([]);
	const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	useEffect(() => {
		const el = document.querySelector("#dropdownMenuButton");
		if (el) {
			el.addEventListener("click", function () {
				setOrderFilters((prevOrderFilters) => {
					return {
						...prevOrderFilters,
						dateType: DateType.CustomDate,
					};
				});
			});
		}
	}, []);

	useEffect(() => {
		let queryParams = {
			since: orderFilters.since,
			until: orderFilters.until,
			status: orderFilters.status,
			isDelivery: orderFilters.isDelivery,
			search: orderFilters.search,
			withError: orderFilters.withError,
			sellerCode: orderFilters.sellerCode,
		};

		history.push(`${orderFilters.page}?${buildURLWithParams(queryParams)}`);
	}, [orderFilters, filtered]);

	const getFilterSelect = (arrStatus, arrModality, doNewOrderRequest) => {
		setLoading(true);

		let filterStatus = arrStatus.filter((element) => {
			return element.checked === true;
		});
		let filterModality = arrModality.filter((element) => {
			return element.checked === true;
		});

		if (doNewOrderRequest) handleFilter(filterStatus, filterModality);
		else setLoading(false);
		let filterData = filterStatus.concat(filterModality);
		setFiltered(filterData);
	};

	const handleFilter = (dataStatus, dataModality) => {
		const onlyStatus = dataStatus.filter(
			(element) => element.value !== OrderFilterStatus.WithError
		);
		const filterOrdersWithError = dataStatus.some(
			(element) => element.value === OrderFilterStatus.WithError
		);

		const filterStatus =
			onlyStatus.length > 0
				? onlyStatus.map((element) => element.value).join(",")
				: null;

		setModality(dataModality);

		const newOrderFilters = {
			...orderFilters,
			page: 1,
			status: filterStatus,
			isDelivery:
				dataModality.length > 0
					? dataModality.filter((modality) => modality.value === "ReciveHome")
							.length > 0
					: null,
			withError: filterOrdersWithError || null,
		};
		setOrderFilters(newOrderFilters);
		getOrders(newOrderFilters);
	};

	const clearOneFilter = (data) => {
		setLoading(true);

		const updatedFilter = filtered.filter(
			(filter) => filter.name !== data.name
		);
		setFiltered(updatedFilter);

		const updatedStatus = orderFilters.status
			?.split(",")
			.filter((element) => element !== data.value)
			.join(",");

		const filterOrdersWithError = updatedFilter.some(
			(objeto) => objeto.value === OrderFilterStatus.WithError
		);

		const shouldClearModality =
			data.value === ShippingMethod.ReciveHome ||
			data.value === ShippingMethod.PickupStore;
		if (shouldClearModality) {
			setModality([]);
		}

		const newOrderFilters = {
			...orderFilters,
			page: 1,
			status: updatedStatus,
			isDelivery: shouldClearModality ? null : orderFilters.isDelivery,
			withError: filterOrdersWithError || null,
		};
		setOrderFilters(newOrderFilters);

		getOrders(newOrderFilters);
	};

	const skeleton = () => {
		return skele.map((index) => (
			<ContentLoader
				key={index}
				speed={5}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="30" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="200" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="370" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="540" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="710" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="880" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="1000" y="28" rx="3" ry="3" width="20" height="10" />
			</ContentLoader>
		));
	};

	return (
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center pt-3 pb-0">
				<h4 className="card-title">Pedidos</h4>
				<ExportButton
					exportFunction={insightsLegacyApi.getExportOrders}
					params={{
						since: orderFilters.since,
						until: orderFilters.until,
						status: orderFilters.status,
						isDelivery: orderFilters.isDelivery,
						search: orderFilters.search,
						withError: orderFilters.withError,
						sellerCode: orderFilters.sellerCode,
						role: localStorage.getItem("role"),
					}}
				/>
			</div>
			<OrderFiltersLayout
				isLoading={loading}
				orderFilters={orderFilters}
				currentSeller={currentSeller}
				setCurrentSeller={setCurrentSeller}
				updateFilters={updateFilters}
				filtered={filtered}
				setFiltered={setFiltered}
				setModality={setModality}
				clearOneFilter={clearOneFilter}
				openModal={openModal}
			/>
			<div className="card-header d-flex flex-column py-0 px-4">
				<div className="d-flex align-items-center" style={{ width: "100%" }}>
					<strong>MOSTRANDO {loading ? "0" : maxCount} RESULTADOS</strong>
				</div>
				{!loading && (
					<FilterOrdersModal
						filterSelect={getFilterSelect}
						arrStatus={orderFilters.status}
						arrModality={modality}
						isWithError={orderFilters.withError}
					/>
				)}
			</div>
			<div className="card-content pt-4">
				<div className="card-body card-list-pagination no-padding">
					{loading ? (
						skeleton()
					) : ordersList.length > 0 ? (
						<List
							data={ordersList}
							page={orderFilters.page}
							colums={OrderTableColumns()}
							overwriteCustomStyles={overwriteOrderStyles}
							conditionalRowStyles={conditionalRowStyles}
							totalRows={maxCount}
							handlePageChange={changePage}
							paginationServer={true}
							rowsPerPage={orderFilters.rowsPerPage}
							handleRowsPerPageChange={changeRowsPerPage}
							highlightOnHover={true}
							pointerOnHover={true}
							onRowClicked={(row) =>
								history.push(`/orders/${orderFilters.page}/detail/${row.id}`)
							}
						/>
					) : (
						<Empty img={"/images/empty_coupon.svg"} orders={true} />
					)}
				</div>
			</div>
		</div>
	);
}
