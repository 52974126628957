import { ChangeEvent, useRef, useState } from "react";
import { retailerManagementApi } from "../../../../infrastructure/api/RetailerManagementApi";
import NewToastComponent from "../../../../components/NewToastComponent";
import { normalizeDigitsOnly } from "../../../../infrastructure/utils/StringUtils";
import { SellerView } from "../../../../domain/views/stores/SellerView";
import { SellerBasicInfoView } from "../../../../domain/views/stores/SellerBasicInfoView";
import { SellerClusterDto } from "../../../../domain/dtos/stores/SellerClusterDto";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../../../infrastructure/utils/FormUtils";
import { StringValidator } from "../../../../infrastructure/utils/TypeValidators/StringValidator";
import { CountryDataType } from "../../../../components/InternationalPhoneInput/InternationalPhoneInputService";

export default function useBasicInformationService(
	sellerId: string,
	createSeller: (basicInfoData: SellerBasicInfoView) => Promise<boolean>,
	updateSellerBasicInfo: (
		sellerId: string,
		sellerData: SellerView,
		basicInfoData: SellerBasicInfoView
	) => Promise<boolean>
) {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [sellerClusters, setSellerClusters] = useState<SellerClusterDto[]>([]);
	const [currentSellerCluster, setCurrentSellerCluster] =
		useState<SellerClusterDto | null>(null);
	const [currentClusterInput, setCurrentClusterInput] = useState<string>("");
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [sellerInfo, setSellerInfo] = useState<SellerBasicInfoView>({
		pointSaleId: null,
		whatsappPhoneNumber: null,
		phoneNumber: null,
		companyName: null,
		fullName: "",
		email: "",
		password: null,
		cnpj: null,
		currentCluster: null,
		isEcommerce: false,
		isActive: false,
	});
	const currentCountryRef = useRef<CountryDataType | null>(null);
	const validationFuncMapper: FormValidations = {
		pointSaleId: InputValidator.isRequired,
		fullName: InputValidator.isRequired,
		email: InputValidator.isEmailValid,
		cnpj: InputValidator.isCnpjValid,
		companyName: InputValidator.isRequired,
		phoneNumber: InputValidator.isPhoneNumberValid,
		whatsappPhoneNumber: (value: string) =>
			InputValidator.isInternationalPhoneInputValid(
				value,
				currentCountryRef.current
			),
		password: InputValidator.isPasswordValid,
	};

	const handleClickShowPassword = () =>
		setShowPassword((show: boolean) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => event.preventDefault();

	const onChangeSeller = (event: ChangeEvent<HTMLInputElement>) => {
		let name = event.target.name;
		let value =
			event.target.value === "on" ? event.target.checked : event.target.value;

		if (validationFuncMapper[name] !== undefined) {
			const isEdition: boolean = !StringValidator.isNullOrWhitespace(sellerId);
			const errMessages: string[] =
				name === "password"
					? validationFuncMapper[name](value, isEdition)
					: validationFuncMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}

		setSellerInfo((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const onWhatsappPhoneChange = (
		phone: string,
		currentCountry: CountryDataType | null
	): void => {
		currentCountryRef.current = currentCountry;
		const errMessages: string[] =
			validationFuncMapper.whatsappPhoneNumber(phone);
		setFormErrors((prevState) => ({
			...prevState,
			["whatsappPhoneNumber"]: errMessages,
		}));
		let completePhone: string = phone;
		if (phone && currentCountry) {
			completePhone = normalizeDigitsOnly(currentCountry.phone + completePhone);
		}
		setSellerInfo((prevState) => ({
			...prevState,
			whatsappPhoneNumber: completePhone,
		}));
	};

	const filterOptions = (option: SellerClusterDto) => {
		option.clusterId = option.clusterId ?? "";
		option.exhibitionName = option.exhibitionName ?? "";
		return option.clusterId + option.exhibitionName;
	};

	const getOptionLabel = (process: SellerClusterDto) =>
		process.exhibitionName || `${process.clusterId} (não identificado)`;

	const isOptionEqualToValue = (
		option: SellerClusterDto,
		value: SellerClusterDto
	) => option.clusterId === value.clusterId;

	const handleFormSubmit = (
		sellerId: string,
		sellerData: SellerView,
		data: SellerBasicInfoView,
		currentCluster: SellerClusterDto | null
	): void => {
		let newData: SellerBasicInfoView = {
			...data,
			currentCluster: currentCluster?.clusterId ?? null,
			...(data?.cnpj && { cnpj: normalizeDigitsOnly(data.cnpj) }),
			...(data?.phoneNumber && {
				phoneNumber: normalizeDigitsOnly(data.phoneNumber),
			}),
			...(data?.whatsappPhoneNumber && {
				whatsappPhoneNumber: normalizeDigitsOnly(data.whatsappPhoneNumber),
			}),
		};

		const formProps = Object.keys(newData);
		const formValues = Object.values(newData);
		const auxiliarProp = !StringValidator.isNullOrWhitespace(sellerId);
		let errors: FormErrors = {};
		for (let i = 0; i < formProps.length; i++) {
			const name = formProps[i];
			const value = formValues[i];
			if (validationFuncMapper[name] === undefined) continue;
			const errMessages: string[] = validationFuncMapper[name](
				value,
				auxiliarProp
			);
			errors[name] = errMessages;
		}

		setFormErrors(errors);

		if (FormValidator.hasError(errors)) return;

		if (!sellerId) {
			handleCreateSeller(newData);
			return;
		}

		handleUpdateSeller(sellerId, sellerData, newData);
	};

	const handleCreateSeller = async (
		data: SellerBasicInfoView
	): Promise<void> => {
		setIsLoading(true);
		let success = await createSeller(data);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: "Parece que tivemos um erro...",
				message: "Tente novamente mais tarde.",
			});
			setIsLoading(false);
			return;
		}

		NewToastComponent({
			status: "success",
			title: "Loja criada com sucesso!",
		});
		setIsLoading(false);
	};

	const handleUpdateSeller = async (
		sellerId: string,
		sellerData: SellerView,
		data: SellerBasicInfoView
	): Promise<void> => {
		setIsLoading(true);
		let success = await updateSellerBasicInfo(sellerId, sellerData, data);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: "Parece que tivemos um erro...",
				message: "Tente novamente mais tarde.",
			});
			setIsLoading(false);
			return;
		}

		NewToastComponent({
			status: "success",
			title: "Loja atualizada com sucesso!",
		});
		setIsLoading(false);
	};

	const getSellerClusters = async (
		currentClusterId: string | null = null
	): Promise<void> => {
		try {
			let data = await retailerManagementApi.getSellerClusters();

			setSellerClusters(data);

			let clusterObject = data.find((cluster: SellerClusterDto) => {
				return (
					cluster.clusterId?.toLocaleLowerCase() ===
					currentClusterId?.toLocaleLowerCase()
				);
			});

			if (clusterObject) {
				setCurrentSellerCluster(clusterObject);
				setCurrentClusterInput(getOptionLabel(clusterObject));
			}

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			return;
		}
	};

	return {
		// Props
		isLoading,
		setIsLoading,
		sellerInfo,
		showPassword,
		sellerClusters,
		currentSellerCluster,
		currentClusterInput,
		formErrors,
		setCurrentClusterInput,
		filterOptions,
		getOptionLabel,
		setCurrentSellerCluster,
		isOptionEqualToValue,
		// Functions
		handleFormSubmit,
		getSellerClusters,
		setSellerInfo,
		onChangeSeller,
		onWhatsappPhoneChange,
		handleClickShowPassword,
		handleMouseDownPassword,
	};
}
