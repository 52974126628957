import { Box, Stack, Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { TCOrder } from "../../../../domain/models/troquecommerce/TCOrder";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";
import { paletteObject } from "../../../../theme/foundations/palette";

type Props = { data: TCOrder | null; isLoading: boolean };

type TimelineSteps = {
	label: string;
	isCompleted: boolean;
};

export default function ERTimeline({ data, isLoading }: Props) {
	const [activeStep, setActiveStep] = useState<number>(0);
	const [timelineSteps, setTimelineSteps] = useState<TimelineSteps[]>([
		{ label: "Em análise", isCompleted: false },
		{ label: "Aguardando envio", isCompleted: false },
		{ label: "Em trânsito", isCompleted: false },
		{ label: "Entrega realizada", isCompleted: false },
		{ label: "Aguardando recebimento", isCompleted: false },
		{ label: "Aguardando NFD", isCompleted: false },
		{ label: "Aguardando pagamento", isCompleted: false },
		{ label: "Finalizado", isCompleted: false },
	]);
	const [isCanceled, setIsCanceled] = useState<boolean>(false);

	useEffect(() => {
		if (data) {
			getActiveStep(data.status);
		}
	}, [data]);

	const getActiveStep = (status: string) => {
		if (status.toLowerCase() === "cancelado") {
			setIsCanceled(true);
			return;
		}

		const activeStepIndex = timelineSteps.findIndex(
			(step) => step.label.toLowerCase() === status.toLowerCase()
		);

		if (status.toLowerCase() === "finalizado") {
			const newTimeline = timelineSteps.map((step) => ({
				...step,
				isCompleted: true,
			}));
			setTimelineSteps(newTimeline);
			setActiveStep(activeStepIndex + 1);
			return;
		}

		const newTimeline = timelineSteps.map((step, index) => {
			if (index < activeStepIndex) {
				return { ...step, isCompleted: true };
			}
			return step;
		});
		setActiveStep(activeStepIndex);
		setTimelineSteps(newTimeline);
	};

	const renderStepper = () => {
		if (isCanceled) {
			return (
				<Stepper
					activeStep={2}
					alternativeLabel
					sx={{
						".MuiStepIcon-root": {
							"&.Mui-completed": {
								color: paletteObject.danger.main,
							},
						},
					}}
				>
					<Step>
						<StepLabel icon="">Em análise</StepLabel>
					</Step>
					<Step>
						<StepLabel icon="">Cancelado</StepLabel>
					</Step>
				</Stepper>
			);
		}
		return (
			<Stepper activeStep={activeStep} alternativeLabel>
				{timelineSteps.map((step: TimelineSteps) => (
					<Step key={step.label}>
						<StepLabel
							icon={" "}
							sx={{
								".MuiStepIcon-root": {
									"&.Mui-completed": {
										color: paletteObject.success.main,
									},
									"&.Mui-active": {
										color: paletteObject.info.main,
									},
								},
							}}
						>
							{step.label}
						</StepLabel>
					</Step>
				))}
			</Stepper>
		);
	};

	return (
		<Box className="card" p={3} pt={4} position="relative">
			<Stack direction="row" spacing={1} mb={3}>
				<CustomTypography variant="secondaryTitle" isBold>
					Status da solicitação:
				</CustomTypography>
				{isLoading || !data ? (
					<Box width={"100px"}>
						<CustomSkeleton quantity={1} height={20} />
					</Box>
				) : (
					<CustomTypography variant="secondaryTitle" sx={{ fontWeight: 500 }}>
						{data.status}
					</CustomTypography>
				)}
			</Stack>
			{isLoading || !data ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				renderStepper()
			)}
		</Box>
	);
}
