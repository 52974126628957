import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { buildURLWithParams } from "../../../infrastructure/utils/BuildURLWithParams";
import { TCList } from "../../../domain/models/troquecommerce/TCList";
import { troqueCommerceApi } from "../../../infrastructure/api/external/TroqueCommerce";
import { TCOrder } from "../../../domain/models/troquecommerce/TCOrder";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { DateType } from "../../../domain/enums/DateType";
import {
	getInitialDateType,
	getSinceFilter,
} from "../../../infrastructure/utils/Dates";

export type ERFilters = {
	page: number;
	status: string | null;
	since_updated_at: string | null;
	ecommerce_number: string; // Can be null, but "" will be considered null by the url builder
	dateType: DateType | null; // For panel usage only
};

type SearchParams = {
	status?: string;
	since_updated_at?: string;
	ecommerce_number?: string;
};

export default function ExchangeReturnListService() {
	const history = useHistory();
	const searchParams = useSearchParams<SearchParams>();
	const { page } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [maxCount, setMaxCount] = useState<number>(0);
	const [exchangeReturnList, setExchangeReturnList] = useState<TCOrder[]>([]);
	const [exchangeReturnFilters, setExchangeReturnFilters] = useState<ERFilters>(
		{
			page: 1,
			status: null,
			since_updated_at: getSinceFilter(DateType.Today),
			ecommerce_number: "",
			dateType: DateType.Today,
		}
	);

	useEffect(() => {
		setIsLoading(true);
		const parsedPage = parseInt(page);
		const updatedERFilters: ERFilters = {
			...exchangeReturnFilters,
			page: parsedPage,
			status: (searchParams.status as string) ?? exchangeReturnFilters.status,
			ecommerce_number:
				(searchParams.ecommerce_number as string) ??
				exchangeReturnFilters.ecommerce_number,
			...(searchParams.since_updated_at && {
				dateType: getInitialDateType(searchParams.since_updated_at as string),
				since_updated_at: searchParams.since_updated_at as string,
			}),
		};
		if (!searchParams.since_updated_at) {
			history.push(
				`${updatedERFilters.page}?${buildURLWithParams({
					status: updatedERFilters.status,
					ecommerce_number: updatedERFilters.ecommerce_number,
					since_updated_at: updatedERFilters.since_updated_at,
				})}`
			);
		}
		setExchangeReturnFilters(updatedERFilters);
		getExchangeReturnList(updatedERFilters);
	}, []);

	const getExchangeReturnList = async (filters: ERFilters): Promise<void> => {
		try {
			const params = {
				page: filters.page,
				status: filters.status,
				since_updated_at: filters.since_updated_at,
				ecommerce_number: filters.ecommerce_number,
			};
			const queryParams = buildURLWithParams(params);
			const response: TCList = await troqueCommerceApi.getTCOrderList(
				queryParams
			);
			setMaxCount(response.count);
			setExchangeReturnList(response.list);
			setIsLoading(false);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoading(false);
		}
	};

	const handlePageChange = (page: number): void => {
		const newExchangeReturnFilters = { ...exchangeReturnFilters, page: page };
		updateExchangeReturnWithFilters(newExchangeReturnFilters);
	};

	const updateExchangeReturnWithFilters = (filters: ERFilters): void => {
		setIsLoading(true);
		history.push(
			`${filters.page}?${buildURLWithParams({
				status: filters.status,
				since_updated_at: filters.since_updated_at,
				ecommerce_number: filters.ecommerce_number,
			})}`
		);

		setExchangeReturnFilters(filters);
		getExchangeReturnList(filters);
	};

	return {
		isLoading,
		maxCount,
		exchangeReturnList,
		exchangeReturnFilters,
		updateExchangeReturnWithFilters,
		handlePageChange,
	};
}
