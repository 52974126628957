import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import Modal from "../../../../components/Modal";
import List from "../../../../components/List/List";
import Empty from "../../../../components/Empty";
import Button from "../../../../components/Button";
import { useVouchers } from "./providers/VouchersContext";
import HeaderSearches from "../../../../components/HeaderSearches/HeaderSearches";
import { API } from "../../../../API";
import { toast } from "react-toastify";

export default function VouchersList(props) {
	const {
		loading,
		setLoading,
		vouchersList,
		maxCount,
		getVouchersList,
		page,
		setPage,
		rowsPerPage,
		setRowsPerPage,
		query,
		setQuery,
		handleExportVouchers,
		setLoadingExport,
		loadingExport,
	} = useVouchers();
	const history = useHistory();
	const [deleteItem, setdeleteItem] = useState("");
	const columsList = [
		{
			name: "Código",
			width: "400px",
			selector: "code",
			sortable: false,
			grow: 2,
			cell: (row) => <span className="vouchers-sub-text">{row.code}</span>,
		},
		{
			sortable: false,
			width: "150px",
			name: "Status",
			selector: "isActive",
			center: true,
			cell: (row) =>
				row.endDate < new Date().toISOString() ? (
					<span className="badge badge-danger stadium w-100">ENCERRADO</span>
				) : row.isActive && row.startDate < new Date().toISOString() ? (
					<span className="badge badge-success stadium w-100">ATIVO</span>
				) : (
					<span className="badge badge-grey-60 stadium w-100">INATIVO</span>
				),
		},
		{
			sortable: false,
			name: "Nº de utilizações",
			selector: "quantityApplied",
			center: true,
			cell: (row) => (
				<span className="vouchers-sub-text">
					{row.quantityApplied}&nbsp;&nbsp;
					{row.quantityApplied === 0 || row.quantityApplied === 1
						? "uso"
						: "usos"}
				</span>
			),
		},
		{
			name: "",
			sortable: false,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<Link
						to={`/vouchers/${page}/edit/${row.id}`}
						className="btn btn-info"
						id="btnGroupDrop1"
					>
						Editar
					</Link>
					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>
					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						{/* <a
							className="dropdown-item"
							data-toggle="modal"
							data-target="#viewVoucher"
							onClick={() => setData(row)}
						>
							Visualizar
						</a> */}
						<a
							className="dropdown-item text-danger"
							data-toggle="modal"
							data-target="#deleteVoucher"
							onClick={() => setdeleteItem(row)}
						>
							Excluir
						</a>
					</div>
				</div>
			),
		},
	];

	useEffect(() => {
		history.push(`${page}?query=${query}`);
		setLoading(true);
		getVouchersList();
	}, [page, rowsPerPage, query]);

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
	};

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setRowsPerPage(e);
		setPage("1");
	};

	const handleChangeSearch = (queryValue) => {
		setLoading(true);
		setQuery(queryValue);
		setPage("1");
	};

	const handleDeleteVouchers = () => {
		API.post("api/vouchers/delete", deleteItem)
			.then(async (response) => {
				window.location.reload();
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Não foi possível remover o cupom de desconto... Tente novamente mais tarde."
				);
			});
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map((index) => (
			<ContentLoader
				key={index}
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	return (
		<div className="container dash-content">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="card-title">Cupons de Desconto</h4>
					<div className="card-actions d-flex flex-row">
						<Link to={`/vouchers/${page}/new`}>
							<Button className="btn-grey-80">Novo Cupom</Button>
						</Link>
					</div>
				</div>
				<div className="m-2 p-2">
					<HeaderSearches
						query={query}
						handleQueryChange={handleChangeSearch}
						handleExportFilter={handleExportVouchers}
						placeholder={"SearchVoucher"}
						setLoadingExport={setLoadingExport}
						loadingExport={loadingExport}
						hasExport={true}
					/>
				</div>
				<div
					className="d-flex align-items-center m-4 "
					style={{ width: "100%", color: "#4B5F79" }}
				>
					<strong>MOSTRANDO TODOS OS CUPONS ({loading ? 0 : maxCount})</strong>
				</div>
				<div className="card-content">
					<div className="card-body no-padding">
						{loading ? (
							skeleton()
						) : vouchersList.length > 0 ? (
							<List
								data={vouchersList}
								page={parseInt(page)}
								colums={columsList}
								totalRows={maxCount}
								handlePageChange={changePage}
								paginationServer={true}
								rowsPerPage={rowsPerPage}
								handleRowsPerPageChange={changeRowsPerPage}
							/>
						) : (
							<Empty
								img={"/images/empty_coupon.svg"}
								genre="seu primeiro"
								name="cupom de desconto"
								link="/vouchers/new"
								import={false}
							/>
						)}
					</div>
				</div>
			</div>

			{/* <Modal
				id={"viewVoucher"}
				title={"Desempenho do cupom"}
				content={
					data != null ? (
						<div>
							<div className="form-group  p-3 card col-12">
								<h2 className="text-success font-weight-bold">
									{data?.amountOrders}
								</h2>
								<h4>Pedidos Fechados</h4>
							</div>
							<div className="form-group p-3 card col-12">
								<h2 className="text-success font-weight-bold">
									R${" "}
									{data?.subTotalOrders?.toLocaleString("pt-br", {
										minimumFractionDigits: 2,
									})}
								</h2>
								<h4>Faturamento</h4>
							</div>

							<div className="form-group p-3 card col-12">
								<h2 className="text-success font-weight-bold">
									R${" "}
									{data?.ticketAverage?.toLocaleString("pt-br", {
										minimumFractionDigits: 2,
									})}
								</h2>
								<h4>Ticket Médio</h4>
							</div>
							<div className="form-group p-3 card col-12">
								<h2 className="text-success font-weight-bold">
									R${" "}
									{data?.discountTotalOrders?.toLocaleString("pt-br", {
										minimumFractionDigits: 2,
									})}
								</h2>
								<h4>Desconto Total Aplicado</h4>
							</div>
						</div>
					) : (
						<Loading />
					)
				}
			/> */}

			<Modal
				id={"deleteVoucher"}
				title={"Certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={handleDeleteVouchers}
			/>
		</div>
	);
}
