import React from "react";
import { IDataTableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import { customStyles } from "../../../../components/List/List";
import { TCOrder } from "../../../../domain/models/troquecommerce/TCOrder";
import { formatDate } from "../../../../infrastructure/utils/FormatMask";
import {
	formatCurrency,
	formatUnknownDocument,
} from "../../../../infrastructure/utils/FormatUtils";

export const overwriteOrderStyles = {
	...customStyles,
	headCells: {
		...customStyles.headCells,
		style: {
			...customStyles.headCells.style,
			textAlign: "center",
			padding: "8px",
		},
	},
	cells: {
		...customStyles.cells,
		style: {
			...customStyles.cells.style,
			padding: "0px 8px",
		},
	},
	rows: {
		...customStyles.rows,
		style: {
			...customStyles.rows.style,
			width: "1110px",
		},
	},
};

export default function ERTableColumns(page: number) {
	const columns: IDataTableColumn<TCOrder>[] = [
		{
			name: "Cliente",
			grow: 5,
			cell: (row) => (
				<div
					className="order-table-client"
					data-tag="allowRowEvents"
					style={{ maxWidth: "100%" }}
				>
					<p
						className="order-table-client-name"
						data-tag="allowRowEvents"
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
						title={row.client.name}
					>
						{row.client.name}
					</p>
					<p data-tag="allowRowEvents">
						Documento: {formatUnknownDocument(row.client.document)}
					</p>
					<small data-tag="allowRowEvents">
						Última Atualização: {formatDate(row.updated_at)}
					</small>
				</div>
			),
		},
		{
			name: "Solicitação reversa",
			grow: 5,
			cell: (row) => (
				<div className="order-table-order" data-tag="allowRowEvents">
					<Link
						className="order-table-order-code"
						style={{ whiteSpace: "nowrap" }}
						target="_blank"
						to={`/exchangeandreturn/${page}/${row.id}`}
					>
						{row.ecommerce_number}
					</Link>
					<p data-tag="allowRowEvents">{formatDate(row.created_at)}</p>
				</div>
			),
		},
		{
			name: "Status",
			center: true,
			grow: 2,
			cell: (row) => (
				<div className={`order-table-status`} data-tag="allowRowEvents">
					<p data-tag="allowRowEvents" style={{ wordBreak: "keep-all" }}>
						{row.status}
					</p>
				</div>
			),
		},
		{
			name: "Tipo",
			center: true,
			grow: 2,
			cell: (row) => (
				<div className={`order-table-status`} data-tag="allowRowEvents">
					<p data-tag="allowRowEvents" style={{ wordBreak: "keep-all" }}>
						{row.reverse_type}
					</p>
				</div>
			),
		},
		{
			name: "Rastreio",
			grow: 3,
			cell: (row) => (
				<div className="order-table-modality" data-tag="allowRowEvents">
					{!row.tracking ? (
						"-"
					) : (
						<>
							<p
								className="order-table-modality-title"
								data-tag="allowRowEvents"
							>
								{row.tracking.status}
							</p>
							<span
								className="order-table-modality-subtitle"
								data-tag="allowRowEvents"
							>
								{row.tracking.courier_company}
							</span>
						</>
					)}
				</div>
			),
		},
		{
			name: "Valor total",
			center: true,
			grow: 2,
			cell: (row) => (
				<div className="order-table-total" data-tag="allowRowEvents">
					{formatCurrency(row.price)}
				</div>
			),
		},
	];

	return columns;
}
