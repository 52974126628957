import { Box, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import { TCOrder } from "../../../../domain/models/troquecommerce/TCOrder";
import CustomSkeleton from "../../../../MUICustomComponents/CustomSkeleton";
import { formatCurrency } from "../../../../infrastructure/utils/FormatUtils";
import { formatDate } from "../../../../infrastructure/utils/FormatMask";

type Props = { data: TCOrder | null; isLoading: boolean };

export default function ERTracking({ data, isLoading }: Props) {
	const renderTrackingContent = () => {
		if (!data?.tracking || !data.tracking.status) {
			return (
				<Stack justifyContent="center" alignItems="center" height="100%">
					<CustomTypography variant="default">
						Não há dados de rastreio
					</CustomTypography>
				</Stack>
			);
		}

		return (
			<Stack direction="column" spacing={0.5}>
				{data.tracking.is_collect && (
					<CustomTypography variant="default" color="grey60">
						<strong>Tipo: </strong>
						{data.tracking.is_collect ? "Coleta" : "Postagem"}
					</CustomTypography>
				)}
				{data.tracking.status && (
					<CustomTypography variant="default" color="grey60">
						<strong>Status do rastreio: </strong>
						{data.tracking.status}
					</CustomTypography>
				)}
				{data.tracking.courier_company && (
					<CustomTypography variant="default" color="grey60">
						<strong>Transportadora: </strong>
						{data.tracking.courier_company}
					</CustomTypography>
				)}
				{data.tracking.courier_collect_number && (
					<CustomTypography variant="default" color="grey60">
						<strong>Autorização de postagem: </strong>
						{data.tracking.courier_collect_number}
					</CustomTypography>
				)}
				{data.tracking.courier_tracking_code && (
					<CustomTypography variant="default" color="grey60">
						<strong>Cód. de rastreio: </strong>
						{data.tracking.courier_tracking_code}
					</CustomTypography>
				)}
				{data.tracking.tracking_url && (
					<CustomTypography variant="default" color="grey60">
						<strong>URL de rastreio: </strong>
						{data.tracking.tracking_url}
					</CustomTypography>
				)}
				{data.tracking.expected_delivery_date && (
					<CustomTypography variant="default" color="grey60">
						<strong>Data prevista de entrega: </strong>
						{formatDate(data.tracking.expected_delivery_date)}
					</CustomTypography>
				)}
				{data.tracking.delivery_date && (
					<CustomTypography variant="default" color="grey60">
						<strong>Data de entrega: </strong>
						{formatDate(data.tracking.delivery_date)}
					</CustomTypography>
				)}
				{data.tracking.posting_address && (
					<CustomTypography variant="default" color="grey60">
						<strong>Endereço de postagem: </strong>
						{data.tracking.posting_address}
					</CustomTypography>
				)}
				{data.tracking.posted_date && (
					<CustomTypography variant="default" color="grey60">
						<strong>Data de postagem: </strong>
						{formatDate(data.tracking.posted_date)}
					</CustomTypography>
				)}
				{data.tracking.price && (
					<CustomTypography variant="default" color="grey60">
						<strong>Valor do frete reverso: </strong>
						{formatCurrency(data.tracking.price)}
					</CustomTypography>
				)}
				{data.tracking.locker && (
					<CustomTypography variant="default" color="grey60">
						<strong>Locker Clique Retire: </strong>
						{data.tracking.locker}
					</CustomTypography>
				)}
			</Stack>
		);
	};

	return (
		<Box className="card" p={3} pt={4} position="relative">
			<div className={`icon-card-detail ${"local_shipping"}`}>
				<i className="material-icons-outlined">local_shipping</i>
			</div>
			<CustomTypography variant="secondaryTitle" isBold marginBottom={1}>
				Rastreio
			</CustomTypography>
			{isLoading || !data ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				renderTrackingContent()
			)}
		</Box>
	);
}
