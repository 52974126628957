import React, {
	ChangeEvent,
	Dispatch,
	ReactNode,
	SetStateAction,
	useState,
} from "react";
import CustomDialog from "../../../../MUICustomComponents/CustomDialog";
import { i18n } from "../../../../translate/i18n";
import { Button, Grid, Stack } from "@mui/material";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import { NFDReceived } from "../../../../domain/models/troquecommerce/Receiveitems/NFDReceived";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import LoadingButtonIcon from "../../../../components/LoadingButtonIcon";
import { ActionButton } from "../../../../domain/types/ActionButton";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";
import { RemoveCircle } from "@mui/icons-material";
type Props = {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	onSubmitCallback: Function;
	isLoadingSubmit: boolean;
};

export default function ERInvoiceDialog({
	isOpen,
	setIsOpen,
	onSubmitCallback,
	isLoadingSubmit,
}: Props) {
	const [nfdData, setNfdData] = useState<NFDReceived>({
		reference: "",
		serie: "",
		danfe: "",
		date: "",
	});
	const [dateValue, setDateValue] = useState<Dayjs | null>(null);
	const [showInvoiceForm, setShowInvoiceForm] = useState<boolean>(false);

	const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setNfdData((prev) => ({ ...prev, [name]: value }));
	};

	const onChangeDate = (date: Dayjs | null): void => {
		setDateValue(date);
		const newDate = date && date.isValid() ? date.toISOString() : "";
		setNfdData((prev) => ({ ...prev, date: newDate }));
	};

	const customConfirmationActions = (): { customActions: ReactNode } => {
		return {
			customActions: (
				<Stack direction="row" gap={2}>
					<Button
						variant="contained"
						color="light"
						onClick={() => setIsOpen(false)}
						disabled={isLoadingSubmit}
					>
						{i18n.t(`buttons.General.BtnCancel`).toString()}
					</Button>
					<Button
						variant="contained"
						color="success"
						onClick={() => onSubmitCallback()}
						disabled={isLoadingSubmit}
					>
						{isLoadingSubmit ? <LoadingButtonIcon /> : "Não inserir"}
					</Button>
					<Button
						variant="contained"
						color="success"
						onClick={() => setShowInvoiceForm(true)}
						disabled={isLoadingSubmit}
					>
						Inserir
					</Button>
				</Stack>
			),
		};
	};

	const renderNFDConfirmation = () => {
		return (
			<Stack direction="column" alignItems="center">
				<CustomIcon
					icon={<RemoveCircle />}
					variant="custom"
					color="danger"
					fontSize="60px"
				/>
				<CustomTypography
					variant="secondaryTitle"
					color="danger"
					marginBottom={2}
					sx={{ textAlign: "center" }}
				>
					Atenção!
				</CustomTypography>
				<CustomTypography variant="default" sx={{ textAlign: "center" }}>
					Deseja inserir uma Nota Fiscal de Devolução?
				</CustomTypography>
			</Stack>
		);
	};

	const renderActionButtons:
		| { actionButton: ActionButton }
		| { customActions: ReactNode } = showInvoiceForm
		? {
				actionButton: {
					text: i18n.t("buttons.General.BtnSave"),
					variant: "contained",
					color: "success",
					onClick: () => onSubmitCallback(nfdData),
					isLoading: isLoadingSubmit,
					isDisabled: isLoadingSubmit,
				},
		  }
		: customConfirmationActions();

	return (
		<CustomDialog
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			dialogInfo={{
				title: "Inserir nota fiscal de devolução",
			}}
			dialogStyles={{
				titleVariant: "title",
				customMaxWidth: "650px",
				actionStyles: { justifyContent: "space-between" },
			}}
			closeCallback={() => setShowInvoiceForm(false)}
			{...renderActionButtons}
		>
			{!showInvoiceForm ? (
				renderNFDConfirmation()
			) : (
				<Grid container spacing={2} mt={0.5}>
					<Grid item xs={12}>
						<CustomInput
							title="Chave"
							name="danfe"
							value={nfdData.danfe}
							onChange={onChange}
							type="number"
						/>
					</Grid>
					<Grid item xs={4}>
						<CustomInput
							title="Nº da nota"
							name="reference"
							value={nfdData.reference}
							onChange={onChange}
							type="number"
						/>
					</Grid>
					<Grid item xs={4}>
						<CustomInput
							title="Série"
							name="serie"
							value={nfdData.serie}
							onChange={onChange}
							type="number"
						/>
					</Grid>
					<Grid item xs={4}>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={"pt-br"}
						>
							<DatePicker
								label="Data de emissão"
								value={dateValue}
								onChange={onChangeDate}
								sx={{ ".MuiInputBase-formControl": { height: "37px" } }}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
			)}
		</CustomDialog>
	);
}
