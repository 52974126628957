import React from "react";
import CustomIcon from "../MUICustomComponents/CustomIcon";
import { RotateRight } from "@mui/icons-material";
import { PaletteDiscriminatedUnion } from "../theme/foundations/palette";
import { SxProps, Theme } from "@mui/material";

type Props = {
	color?: PaletteDiscriminatedUnion;
	sx?: SxProps<Theme>;
};

export default function LoadingButtonIcon({ color, sx }: Props) {
	return (
		<CustomIcon
			icon={<RotateRight />}
			variant="default"
			isRotating
			{...(color && { color: color })}
			{...(sx && { sx: sx })}
		/>
	);
}
