import React, { useState, useEffect } from "react";
import {
	onMessageListener,
	getNotificationPermission,
} from "../../../push-notification";
import { APIV2 } from "../../../API";
import MenuBar from "../MenuBar/index.js";
import { toast } from "react-toastify";
import Settings from "./components/Settings";
import Notifications from "./components/Notificatiosn";
import FixedScreenNotification from "./components/FixedScreenNotification";
import { useLocation, matchPath, Link } from "react-router-dom";
import { useConfig } from "../../../providers/ConfigProvider";

export default function Menu() {
	const { retailerConfigs, profileConfigs } = useConfig();
	const I18N_STORAGE_KEY = "i18nextLng";
	const [language] = useState(localStorage.getItem(I18N_STORAGE_KEY));
	const routeLocation = useLocation();
	const userName = "Administrador";

	const pathScreenDict = {
		"/orders/:page/detail/:id": "OrderDetails",
		"/export/:page": "Export",
		"/catalog/management/categories": "Categories",
		"/settings/payment": "PaymentConfig",
	};
	const [matchScreenName, setMatchScreenName] = useState("");
	useEffect(() => {
		let match = matchPath(routeLocation.pathname, {
			path: [
				"/orders/:page/detail/:id",
				"/export/:page",
				"/catalog/management/categories",
				"/settings/payment",
			],
			exact: false,
			strict: false,
		});
		setMatchScreenName(pathScreenDict[match?.path] || "");
	}, [routeLocation]);

	onMessageListener()
		.then((payload) => {
			if (!payload) return;
			let audio = new Audio("/images/alert-push-01.mp3");
			audio.play();
			toast.success(
				<button
					onClick={() => (window.location.href = "/orders/1")}
					className="push-order"
				>
					{payload.notification.title}
				</button>
			);
		})
		.catch((err) => console.log("failed: ", err));

	useEffect(() => {
		if (navigator.vendor !== "Apple Computer, Inc.") {
			getNotificationPermission()
				.then((firebaseToken) => {
					let body = {
						Token: firebaseToken,
						Role: localStorage.getItem("role"),
					};
					APIV2.put("/management/retailer/pushnotificationtoken", body);
				})
				.catch((err) => {
					return err;
				});
		}
	}, [language]);

	return (
		<>
			<header className="header header-primary">
				<div className="container d-flex align-items-center">
					<button
						className="navbar-toggler d-block d-lg-none"
						type="button"
						data-toggle="collapse"
						data-target="#conteudoNavbarSuportado"
						aria-controls="conteudoNavbarSuportado"
						aria-expanded="true"
						aria-label="Alterna navegação"
					>
						<span className="material-icons">menu</span>
					</button>
					<Link className="brand" to="/dashboard">
						<span className="brand-logo">
							<img src={profileConfigs?.logo} alt="logo" height="28" />
						</span>
					</Link>
					<div className="navbar-wrapper">
						<ul className="nav float-right d-flex align-items-center">
							<Notifications />
							<Settings />
							<div className="d-flex">
								<div className="user-nav">
									<span className="user-name">{userName}</span>
									<span className="user-role">
										{localStorage.getItem("retailerName")}
									</span>
								</div>
								<span className="user-avatar d-flex align-items-center">
									<img
										className="round"
										src={
											retailerConfigs?.retailer?.brandIconUrl
												? retailerConfigs?.retailer?.brandIconUrl
												: "/images/avatar.jpg"
										}
										alt="avatar"
										height="40"
										width="40"
										onError={({ currentTarget }) => {
											currentTarget.onerror = null; // prevents looping
											currentTarget.src = "/images/avatar.jpg";
										}}
									/>
								</span>
							</div>
						</ul>
					</div>
				</div>
			</header>
			{localStorage.getItem("useStateMachine") === "true" &&
				matchScreenName !== "" && (
					<FixedScreenNotification matchScreenName={matchScreenName} />
				)}
			{localStorage.getItem("useStateMachine") === "false" &&
				matchScreenName !== "OrderDetails" &&
				matchScreenName !== "" && (
					<FixedScreenNotification matchScreenName={matchScreenName} />
				)}
			<MenuBar />
		</>
	);
}
