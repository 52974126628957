import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import CustomDialog from "../../../MUICustomComponents/CustomDialog";
import { i18n } from "../../../translate/i18n";
import CustomCheckbox from "../../../MUICustomComponents/CustomCheckbox";
import { Grid } from "@mui/material";
import { TCStatus } from "../../../domain/models/troquecommerce/enums/TCStatus";
import { ERFilters } from "./ExchangeReturnListService";

type Props = {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	exchangeReturnFilters: ERFilters;
	filterStatuses: (
		statusList: erStatusFilter[],
		callUpdateRequest: boolean
	) => void;
};

export type erStatusFilter = {
	id: TCStatus;
	isChecked: boolean;
	label: string;
};

export default function ERStatusDialog({
	isOpen,
	setIsOpen,
	exchangeReturnFilters,
	filterStatuses,
}: Props) {
	const [statusList, setStatusList] = useState<erStatusFilter[]>([
		{
			id: TCStatus.aguardando_envio,
			isChecked: false,
			label: "Aguardando envio",
		},
		{ id: TCStatus.em_transito, isChecked: false, label: "Em transito" },
		{ id: TCStatus.aguardando_nfd, isChecked: false, label: "Aguardando NFD" },
		{
			id: TCStatus.entrega_realizada,
			isChecked: false,
			label: "Entrega realizada",
		},
		{
			id: TCStatus.aguardando_pagamento,
			isChecked: false,
			label: "Aguardando pagamento",
		},
		{ id: TCStatus.finalizado, isChecked: false, label: "Finalizado" },
		{
			id: TCStatus.aguardando_recebimento,
			isChecked: false,
			label: "Aguardando recebimento",
		},
		{
			id: TCStatus.problemas_no_envio,
			isChecked: false,
			label: "Problemas no envio",
		},
		{ id: TCStatus.cancelado, isChecked: false, label: "Cancelado" },
		{
			id: TCStatus.processando_pagamento,
			isChecked: false,
			label: "Processando pagamento",
		},
		{ id: TCStatus.em_analise, isChecked: false, label: "Em análise" },
	]);

	useEffect(() => {
		getStatusOrders();
	}, [exchangeReturnFilters]);

	const getStatusOrders = () => {
		const result = statusList.map((obj) => {
			if (exchangeReturnFilters.status?.includes(obj.id)) {
				return { ...obj, isChecked: true };
			} else {
				return { ...obj, isChecked: false };
			}
		});
		setStatusList(result);
		filterStatuses(result, false);
	};

	const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;
		setStatusList((prev) =>
			prev.map((status) =>
				status.id === name ? { ...status, isChecked: checked } : status
			)
		);
	};

	const submitStatus = () => {
		setIsOpen(false);
		filterStatuses(statusList, true);
	};

	return (
		<CustomDialog
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			dialogInfo={{
				title: i18n.t("erStatus.DialogTitle"),
				subtitle: i18n.t("erStatus.DialogSubtitle"),
			}}
			dialogStyles={{
				titleVariant: "title",
				customMaxWidth: "500px",
			}}
			actionButton={{
				text: i18n.t(`buttons.General.BtnSave`),
				variant: "contained",
				color: "success",
				onClick: submitStatus,
			}}
		>
			<Grid container spacing={2}>
				{statusList.map((status: erStatusFilter) => (
					<Grid item xs={6} key={status.id}>
						<CustomCheckbox
							id={status.id}
							isChecked={status.isChecked}
							label={status.label}
							onChange={handleStatusChange}
						/>
					</Grid>
				))}
			</Grid>
		</CustomDialog>
	);
}
