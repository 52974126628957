import React from "react";
import { i18n } from "../../translate/i18n";
import Sidebar, { SidebarItem } from "../../components/Sidebar/Sidebar";
import { Grid } from "@mui/material";
import { PaymentConfig } from "./enums/PaymentConfig";
import { useParams, Switch, Route, Redirect } from "react-router-dom";
import CommercialConditions from "./CommercialConditions/CommercialConditions";

export default function PaymentConfigurations() {
	const { screen } = useParams();

	const items: SidebarItem[] = [
		{
			title: i18n.t("paymentConfig.CommercialConditions"),
			link: "/settings/payment/commercial-conditions",
			isActive: screen === PaymentConfig.CommercialConditions,
		},
	];

	return (
		<div className="container dash-content">
			<Grid container spacing={2}>
				<Grid item xs={4} p={0}>
					<Sidebar items={items} />
				</Grid>
				<Grid item xs={8}>
					<Switch>
						<Route
							path="/settings/payment"
							exact
							children={
								<Redirect to="/settings/payment/commercial-conditions" />
							}
						/>
						<Route
							path="/settings/payment/commercial-conditions"
							children={<CommercialConditions />}
						/>
					</Switch>
				</Grid>
			</Grid>
		</div>
	);
}
