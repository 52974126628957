import React, { useCallback, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import "react-widgets/dist/css/react-widgets.css";
import { toast } from "react-toastify";
import Modal from "../../../../components/Modal";
import { APIV2 } from "../../../../API";
import Reload from "./Reload.jsx";
import MaskPrice from "../../../../components/MaskPrice";
import Button from "../../../../components/Button";
import { Link } from "react-router-dom";

export default function PriceProducts(props) {
	const [sellerId, setSellerId] = useState("");
	const [prices, setPrices] = useState([]);
	const [sellers, setSellers] = useState([]);
	const [oldPrices, setOldPrices] = useState(null);
	const [newPrices, setNewPrices] = useState(null);

	const [oldPrice, setOldPrice] = useState("");
	const [price, setPrice] = useState("");

	const [edit, setEdit] = useState(false);
	const [id, setId] = useState("");
	const [deletePrice, setDeletePrice] = useState("");
	const [reloadPrices, setReloadPrices] = useState(true);
	const [pricesError, setPricesError] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getAllPrices();
	}, []);

	useEffect(() => {
		const filteredSellers = props.sellers.filter((o1) =>
			prices.every((o2) => o1.id !== o2.sellerId)
		);
		setSellers(filteredSellers);
	}, [props.sellers, prices]);

	const getAllPrices = () => {
		APIV2.get("catalog/products/" + props.id + "/prices")
			.then(async (response) => {
				setPrices(response.data);
				if (!props.isSinglePrice && props.sellers.length > 0) {
					localStorage.getItem("UseCatalogProcessor") === "true"
						? setSellerId(props.sellers[0].pointSaleId)
						: setSellerId(props.sellers[0].id);
				}
				setReloadPrices(false);
			})
			.catch((error) => {
				setReloadPrices(false);
				setPricesError(true);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
				return;
			});
	};
	const clean = () => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
		setEdit(false);
		setPrice("");
		setOldPrice("");
		setSellerId("");
		setId("");
	};
	const getPrice = (item) => {
		setPrice(item.price);
		setEdit(true);
		setId(item.id);
		setOldPrice(item.oldPrice);
		localStorage.getItem("UseCatalogProcessor") === "true"
			? setSellerId(item.sellerCode)
			: setSellerId(item.sellerId);
	};

	const changeSelect = (e) => {
		setSellerId(e.target.value);
	};

	const handlePrice = useCallback((e) => {
		e.preventDefault();
		let value = e.currentTarget.value;
		value = value.replace(/\D/g, "");
		value = value.replace(/(\d)(\d{2})$/, "$1.$2");
		e.currentTarget.value = value;
		setPrice(value);
	}, []);

	const handleOldPrice = useCallback((e) => {
		e.preventDefault();
		let oldPrice = e.currentTarget.value;
		oldPrice = oldPrice.replace(/\D/g, "");
		oldPrice = oldPrice.replace(/(\d)(\d{2})$/, "$1.$2");
		e.currentTarget.value = oldPrice;
		setOldPrice(oldPrice);
	}, []);

	const create = async () => {
		setLoading(true);
		var button = document.getElementById("submit");
		button.setAttribute("disabled", "disabled");

		let bodyPost = {
			sellerId: props?.isSinglePrice ? props?.sellers[0]?.id : sellerId,
			oldPrice: !oldPrice ? "0.00" : oldPrice,
			price: !price ? "0.00" : price,
		};

		let bodyPut = {
			sellerId: sellerId,
			oldPrice: !oldPrice ? "0.00" : oldPrice,
			price: !price ? "0.00" : price,
			id: id,
		};

		var bodyCatalog = {
			sellerCode: sellerId,
			oldPrice: !oldPrice ? "0.00" : oldPrice,
			price: !price ? "0.00" : price,
		};

		if (id || edit) {
			APIV2.put(
				localStorage.getItem("UseCatalogProcessor") === "true"
					? "catalog/products/" + props.id + "/prices"
					: "catalog/products/" + props.id + "/prices/" + id,
				localStorage.getItem("UseCatalogProcessor") === "true"
					? bodyCatalog
					: bodyPut
			)
				.then(() => {
					getAllPrices();
					setOldPrice("");
					setPrice("");
					toast.success("Preço editado com sucesso!😃");
					setLoading(false);
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						toast.error(error.response.data.message);
						return;
					}
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
					return;
				});
		} else {
			APIV2.post(
				"catalog/products/" + props.id + "/prices",
				localStorage.getItem("UseCatalogProcessor") === "true"
					? bodyCatalog
					: bodyPost
			)
				.then(() => {
					getAllPrices();
					setOldPrice("");
					setPrice("");
					toast.success("Preço criado com sucesso!😃");
					setLoading(false);
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						toast.error(error.response.data.message);
						return;
					}
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
					return;
				});
		}
	};

	const setDelete = (price) => {
		setDeletePrice(price);
	};
	const excludePrice = () => {
		APIV2.delete(
			localStorage.getItem("UseCatalogProcessor") === "true"
				? `/catalog/products/${props.id}/prices?sellerCode=${
						deletePrice.sellerCode ? deletePrice.sellerCode : ""
				  }`
				: "catalog/products/" + props.id + "/prices/" + deletePrice.id
		)
			.then(async () => {
				getAllPrices();
				toast.success("Preço deletado com sucesso!😃");
				setLoading(false);
			})
			.catch((error) => {
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const skeleton = () => {
		const skele = [0, 1, 2];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	const skeletonPrice = () => {
		const skele = [0];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 400"
				style={{ width: "100%", height: "150px" }}
			>
				<rect x="10" y="10" rx="15" ry="15" width="1080" height="100" />
				<rect x="10" y="180" rx="10" ry="10" width="200" height="25" />
				<rect x="10" y="220" rx="15" ry="15" width="500" height="100" />

				<rect x="600" y="180" rx="10" ry="10" width="200" height="25" />
				<rect x="600" y="220" rx="15" ry="15" width="500" height="100" />
			</ContentLoader>
		));
	};

	const removeModal = () => {
		var node = document.querySelector(".modal-open");
		if (node.parentNode) {
			node.parentNode.removeChild(node);
		}
	};

	const renderPriceList = () => {
		return (
			<>
				{prices.length === 0 && reloadPrices ? (
					skeleton()
				) : (
					<table className="table">
						<thead>
							<tr>
								<th scope="col">Preço Antigo</th>
								<th scope="col">Preço Atual</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{prices.map((item, i) => (
								<tr key={item.id} id={item.id}>
									<td>
										{oldPrices === null ? (
											<span>
												R${" "}
												{item.oldPrice.toLocaleString("pt-br", {
													minimumFractionDigits: 2,
												})}
											</span>
										) : (
											<span>{oldPrice}</span>
										)}
									</td>
									<td>
										{newPrices === null ? (
											<span>
												R${" "}
												{item.price.toLocaleString("pt-br", {
													minimumFractionDigits: 2,
												})}
											</span>
										) : (
											<span>{newPrices}</span>
										)}
									</td>
									<td className="text-right">
										<div className=" btn-group" role="group">
											<button
												type="button"
												className="btn btn-light outline btn-icon btn-sm"
												data-toggle="modal"
												data-target="#priceModal"
												onClick={() => getPrice(item)}
											>
												<i className="material-icons">edit</i>
											</button>
											<button
												type="button"
												className="btn btn-light outline btn-icon btn-sm text-danger"
												data-toggle="modal"
												data-target="#deletePreco"
												onClick={() => setDelete(item)}
											>
												<i className="material-icons">delete_outline</i>
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</>
		);
	};

	return (
		<div className="card mt-3">
			<div className="card-header d-flex justify-content-between align-items-center">
				<h4 className="card-title">Preços do Produto</h4>
				<div className="card-actions text-right">
					{!reloadPrices && props.isSinglePrice && prices.length === 0 && (
						<Button
							type="button"
							loading={loading}
							disable={loading}
							className="btn-grey-80"
							dataToggle="modal"
							dataTarget="#priceModal"
							onClick={() => clean()}
						>
							Criar novo preço
						</Button>
					)}

					{!reloadPrices && !props.isSinglePrice && (
						<Button
							type="button"
							loading={loading}
							disable={loading}
							className="btn-grey-80"
							dataToggle="modal"
							dataTarget="#priceModal"
							onClick={() => clean()}
						>
							Criar novo preço
						</Button>
					)}
				</div>
			</div>
			<div className="card-content">
				<div className="card-body pt-0">
					{pricesError ? (
						<Reload func={() => getAllPrices()} />
					) : (
						renderPriceList()
					)}
				</div>
			</div>
			<Modal
				id="priceModal"
				title={sellerId ? "Editar preço" : "Criar preço"}
				create={!id}
				edit={!!id}
				removeCloseButton={true}
				disable={
					((!props.isSinglePrice || !price) && (!sellerId || !price)) ||
					props.sellers.length === 0
				}
				content={
					loading === true ? (
						skeletonPrice()
					) : (
						<div className="row">
							{props.isSinglePrice == false && (
								<div className="form-group col-12">
									<label htmlFor="inputGroupSelect01 " className="w-100">
										{edit ? (
											<select
												className="custom-select"
												disabled={edit ? true : false}
												id="inputGroupSelect01"
												value={sellerId}
												onChange={changeSelect}
											>
												<option value="">Selecione uma loja</option>
												{props.sellers.map((i, index) => (
													<option
														value={
															localStorage.getItem("UseCatalogProcessor") ===
															"true"
																? i.pointSaleId
																: i.id
														}
														key={index}
													>
														{i.fullName}
													</option>
												))}
											</select>
										) : (
											<>
												{sellers.length > 0 && (
													<select
														className="custom-select "
														disabled={edit ? true : false}
														id="inputGroupSelect01"
														value={sellerId}
														onChange={changeSelect}
													>
														<option value="">Selecione uma loja</option>
														{sellers.map((i, index) => (
															<option
																value={
																	localStorage.getItem(
																		"UseCatalogProcessor"
																	) === "true"
																		? i.pointSaleId
																		: i.id
																}
																key={index}
															>
																{i.fullName}
															</option>
														))}
													</select>
												)}
											</>
										)}
									</label>
								</div>
							)}
							{props.sellers.length === 0 &&
								localStorage.getItem("UseCatalogProcessor") !== "true" && (
									<div className="alert alert-warning col-12" role="alert">
										Atenção! Para cadastrar e/ou alterar um preço de produto, é
										preciso ter pelo menos uma loja ativa! Para gerenciar suas
										lojas,
										<a
											href="/stores/1"
											dataToggle="modal"
											dataTarget={`#priceModal`}
										>
											clique aqui!
										</a>
									</div>
								)}
							<div className="form-group col-6">
								<label htmlFor="oldPrice">
									<strong>Preço antigo</strong>
								</label>
								<div className="input-group d-flex flex-row">
									<div className="input-group-prepend">
										<MaskPrice
											defaultValue={String(
												oldPrice.toLocaleString("pt-br", {
													minimumFractionDigits: 2,
												})
											)}
											name="oldPrice"
											onChange={handleOldPrice}
										/>
									</div>
								</div>
							</div>

							<div className="form-group col-6">
								<label htmlFor="price">
									<strong>Preço atual</strong>
								</label>
								<div className="input-group d-flex flex-row">
									<div className="input-group-prepend">
										<MaskPrice
											defaultValue={String(
												price.toLocaleString("pt-br", {
													minimumFractionDigits: 2,
												})
											)}
											name="price"
											onChange={handlePrice}
										/>
									</div>
								</div>
							</div>
						</div>
					)
				}
				buttonId={"submit"}
				actionCreate={create}
				actionEdit={create}
			/>
			<Modal
				id={"deletePreco"}
				title={"Certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={excludePrice}
			/>
		</div>
	);
}
