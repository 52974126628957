import React, { useEffect, useState } from "react";
import { i18n } from "../translate/i18n";

const SearchInputClose = ({
	value,
	onChange,
	placeholder,
	loading,
	alwaysShowClose = false,
}) => {
	const [searchData, setSearchData] = useState(value);

	useEffect(() => setSearchData(value), [value]);

	const handleChange = (event) => {
		setSearchData(event.target.value);
	};

	const handleCloseChange = () => {
		setSearchData("");
		onChange("");
	};

	const closeButton = () => {
		return (
			<div className="neo-icons-cancel">
				<button type="button" disabled={loading} onClick={handleCloseChange}>
					<i className="material-icons">cancel</i>
				</button>
			</div>
		);
	};

	return (
		<div className="neo-form">
			{!alwaysShowClose && (
				<label className="neo-form-search-label" htmlFor="search">
					{i18n.t("searchInputClose.Search")}
				</label>
			)}
			<div className="neo-form-search">
				<div className="neo-icons-search">
					<button onClick={() => onChange(searchData)}>
						<i className="material-icons">search</i>
					</button>
				</div>

				<input
					disabled={loading}
					className="neo-input-search"
					value={searchData}
					placeholder={placeholder}
					onChange={handleChange}
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							onChange(searchData);
						}
					}}
					id="search"
					name="search"
				/>

				{alwaysShowClose
					? closeButton()
					: searchData.length > 0 && closeButton()}
			</div>
		</div>
	);
};

export default SearchInputClose;
