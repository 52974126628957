import {
	Button,
	ButtonGroup,
	Grid,
	Popover,
	useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { i18n } from "../../../translate/i18n";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import ERStatusDialog, { erStatusFilter } from "./ERStatusDialog";
import { ERFilters } from "./ExchangeReturnListService";
import { DateType } from "../../../domain/enums/DateType";
import { getSinceFilter } from "../../../infrastructure/utils/Dates";
import { paletteObject } from "../../../theme/foundations/palette";
import SearchInputClose from "../../../components/SearchInputClose";
import LoadingButtonIcon from "../../../components/LoadingButtonIcon";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import { formatDateStringOnly } from "../../../infrastructure/utils/FormatMask";

type Props = {
	exchangeReturnFilters: ERFilters;
	isLoading: boolean;
	updateExchangeReturnWithFilters: (filters: ERFilters) => void;
};

export default function ERListFilters({
	exchangeReturnFilters,
	isLoading,
	updateExchangeReturnWithFilters,
}: Props) {
	const isCustomBreakpoint = useMediaQuery(`(max-width:992px)`);
	const [isFilterDialogOpen, setIsFilterDialogOpen] = useState<boolean>(false);
	const [statusFilterButtons, setStatusFilterButtons] = useState<
		erStatusFilter[]
	>([]);
	const [customDateValue, setCustomDateValue] = useState<Dayjs | null>(null);
	const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
		null
	);

	const updateFilters = (filteredStatusList: erStatusFilter[]): void => {
		const filteredStatusString = separateStatusesAsString(filteredStatusList);
		const updatedFilters: ERFilters = {
			...exchangeReturnFilters,
			page: 1,
			status: filteredStatusString,
		};
		updateExchangeReturnWithFilters(updatedFilters);
	};

	const onChangeDate = (
		dateType: Exclude<DateType, DateType.CustomDate>
	): void => {
		const since_updated_at = getSinceFilter(dateType);
		const updatedFilters: ERFilters = {
			...exchangeReturnFilters,
			page: 1,
			dateType: dateType,
			since_updated_at: since_updated_at,
		};
		setCustomDateValue(null);
		updateExchangeReturnWithFilters(updatedFilters);
	};

	const onChangeCustomDate = (date: Dayjs | null): void => {
		setCustomDateValue(date);
		if (!date || !date.isValid()) return;
		const newSinceDate = date.toISOString();
		const updatedFilters: ERFilters = {
			...exchangeReturnFilters,
			page: 1,
			dateType: DateType.CustomDate,
			since_updated_at: newSinceDate,
		};
		updateExchangeReturnWithFilters(updatedFilters);
	};

	const handleCustomDateClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setPopoverAnchor(event.currentTarget);
	};

	const handleCustomDateClose = () => {
		setPopoverAnchor(null);
	};

	const separateStatusesAsString = (statusList: erStatusFilter[]): string => {
		if (statusList.length <= 0) return "";
		const onlyStatusIds: string[] = statusList.map((status) => status.id);
		const statusAsString: string = onlyStatusIds.join(",");
		return statusAsString;
	};

	const filterStatuses = (
		statusList: erStatusFilter[],
		callUpdateRequest: boolean = false
	): void => {
		const checkedStatusList = statusList.filter((status) => status.isChecked);
		setStatusFilterButtons(checkedStatusList);
		if (callUpdateRequest) updateFilters(checkedStatusList);
	};

	const clearOneStatusFilter = (statusBtn: erStatusFilter): void => {
		const newFilteredStatuses = statusFilterButtons.filter(
			(checkedStatus) => checkedStatus.id !== statusBtn.id
		);
		setStatusFilterButtons(newFilteredStatuses);
		updateFilters(newFilteredStatuses);
	};

	const clearAllFilters = (): void => {
		setStatusFilterButtons([]);
		const resetFilters: ERFilters = {
			page: 1,
			status: null,
			since_updated_at: getSinceFilter(DateType.Today),
			dateType: DateType.Today,
			ecommerce_number: "",
		};
		updateExchangeReturnWithFilters(resetFilters);
	};

	const handleSearch = (search: string): void => {
		const updatedFilters: ERFilters = {
			...exchangeReturnFilters,
			page: 1,
			ecommerce_number: search,
		};
		updateExchangeReturnWithFilters(updatedFilters);
	};

	const dateFilterButton = (
		dateType: Exclude<DateType, DateType.CustomDate>
	): JSX.Element => {
		return (
			<Button
				onClick={() => onChangeDate(dateType)}
				disabled={isLoading}
				{...(exchangeReturnFilters.dateType === dateType && {
					sx: {
						backgroundColor: paletteObject.grey80.main,
						color: `${paletteObject.white.main} !important`,
						":hover": { backgroundColor: paletteObject.grey80.main },
					},
				})}
			>
				{isLoading && exchangeReturnFilters.dateType === dateType ? (
					<LoadingButtonIcon color="white" />
				) : (
					i18n.t(`erList.${dateType}`).toString()
				)}
			</Button>
		);
	};

	const getCustomButtonContent = () => {
		if (exchangeReturnFilters.dateType !== DateType.CustomDate) {
			return i18n.t(`erList.${DateType.CustomDate}`).toString();
		}
		if (isLoading) {
			return <LoadingButtonIcon />;
		}
		return formatDateStringOnly(exchangeReturnFilters.since_updated_at);
	};

	return (
		<>
			<ERStatusDialog
				isOpen={isFilterDialogOpen}
				setIsOpen={setIsFilterDialogOpen}
				exchangeReturnFilters={exchangeReturnFilters}
				filterStatuses={filterStatuses}
			/>
			<Grid
				container
				spacing={2}
				className="filter-orders"
				alignItems={"flex-end"}
			>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<SearchInputClose
						value={exchangeReturnFilters.ecommerce_number ?? ""}
						onChange={handleSearch}
						placeholder={"Código do pedido"}
						loading={isLoading}
					/>
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<CustomTypography variant="small" isBold marginBottom={1}>
						{i18n.t("erList.SelectedPeriod").toString()}
					</CustomTypography>
					<ButtonGroup
						id="dateRangeSelect"
						color="light"
						variant="outlined"
						fullWidth
						sx={{
							"& .MuiButtonGroup-grouped": {
								borderWidth: 2,
								color: paletteObject.grey50.main,
								fontSize: 12,
								":hover": {
									borderWidth: 2,
								},
							},
						}}
					>
						{dateFilterButton(DateType.Today)}
						{dateFilterButton(DateType.SevenDays)}
						{dateFilterButton(DateType.ThirtyDays)}
						<Button
							onClick={handleCustomDateClick}
							disabled={isLoading}
							{...(exchangeReturnFilters.dateType === DateType.CustomDate && {
								sx: {
									backgroundColor: paletteObject.grey80.main,
									color: `${paletteObject.white.main} !important`,
									":hover": { backgroundColor: paletteObject.grey80.main },
								},
							})}
						>
							{getCustomButtonContent()}
						</Button>
					</ButtonGroup>
					<Popover
						id={"customDateCalendar"}
						open={Boolean(popoverAnchor)}
						anchorEl={popoverAnchor}
						onClose={handleCustomDateClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
					>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<StaticDatePicker
								value={customDateValue}
								onChange={onChangeCustomDate}
								disableFuture
								slotProps={{
									toolbar: { hidden: true },
									actionBar: { hidden: true },
								}}
							/>
						</LocalizationProvider>
					</Popover>
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<div className="d-flex">
						<button
							className="btn btn-outline d-flex justify-content-center mr-1"
							style={{
								background: "transparent",
								border: "1px solid #5A5E61",
								color: "#5A5E61",
								width: "190px",
								height: "40px",
							}}
							type="button"
							onClick={() => setIsFilterDialogOpen(true)}
						>
							<i
								className="material-icons-outlined"
								style={{ marginRight: "8px" }}
							>
								filter_alt
							</i>
							{i18n
								.t("erList.FilterBtn")
								.replace(
									"%X",
									statusFilterButtons.length === null
										? "0"
										: `${statusFilterButtons.length}`
								)}
						</button>
						<button
							className="btn btn-icon d-flex justify-content-center mx-1"
							onClick={clearAllFilters}
							style={{
								border: "1px solid #747E8B",
								background: "#747E8B",
								color: "#FFFFFF",
								width: "190px",
								height: "40px",
							}}
						>
							<i className="material-icons" style={{ marginRight: "8px" }}>
								delete_outline
							</i>
							{i18n.t("erList.ClearFilters").toString()}
						</button>
					</div>
				</Grid>
				{statusFilterButtons.length > 0 && (
					<Grid item xs={12}>
						<CustomTypography variant="small" isBold marginBottom={1}>
							{i18n.t("erList.AddedFilters").toString()}
						</CustomTypography>
						<div className="d-flex flex-row flex-wrap">
							{statusFilterButtons.map((checkedStatus) => (
								<button
									key={checkedStatus.id}
									className="btn-filtered mt-2"
									style={
										isLoading
											? { cursor: "not-allowed" }
											: { cursor: "pointer" }
									}
									disabled={isLoading}
									onClick={() => clearOneStatusFilter(checkedStatus)}
								>
									{checkedStatus.label} <i className="material-icons">close</i>
								</button>
							))}
						</div>
					</Grid>
				)}
			</Grid>
		</>
	);
}
