import React from "react";
import IndexLogin from "./screens/Login/Login";
import Dashboard from "./screens/Dashboard/Dashboard.jsx";
import Uikit from "./screens/Ui-kit/Index";
import ChannelManagement from "./screens/ChannelManagement/Index";
import Vouchers from "./screens/Catalog/Benefits/Vouchers/Vouchers";
import Promotions from "./screens/Catalog/Benefits/Promotions/Promotions";
import NewPromotions from "./screens/Catalog/Benefits/Promotions/NewPromotions";
import Import from "./screens/Import/Import";
import Products from "./screens/Catalog/Products/Products";
import NewProduct from "./screens/Catalog/Products/NewProduct";
import NewSku from "./screens/Catalog/Products/NewSku.jsx";
import Skus from "./screens/Catalog/Sku/Index.jsx";
import NewSkus from "./screens/Catalog/Sku/NewSku.jsx";
import Collections from "./screens/Catalog/Collections/Collections";
import NewCollection from "./screens/Catalog/Collections/NewCollection.jsx";
import NewVoucher from "./screens/Catalog/Benefits/Vouchers/NewVouchers";
import Welcome from "./screens/AutoSetup/Welcome";
import Onboarding from "./screens/AutoSetup/Onboarding/Index";
import Brand from "./screens/AutoSetup/Brand/Index";
import Stores from "./screens/AutoSetup/Stores/Index";
import AppStore from "./screens/AutoSetup/AppStore/Index";
import Content from "./screens/AutoSetup/Content/Index";
import Confirm from "./screens/AutoSetup/Confirm";
import ResetPassword from "./screens/Login/components/ResetPassword.jsx";
import Error from "./screens/404/Index";
import StoresPanel from "./screens/Stores/Index";
import NewStoresPanel from "./screens/Stores/New";
import Retailers from "./screens/Settings/Retailers/Retailers.jsx";
import NewRetailers from "./screens/Settings/Retailers/components/New.jsx";
import Categories from "./screens/Categories/Categories.tsx";

import SMOrders from "./screens/SMOrders/Index";
import SMDetailsOrders from "./screens/SMOrders/Orders";

import Orders from "./screens/Orders/Index";
import DetailsOrders from "./screens/Orders/Orders";

import LayoutWrapper from "./layouts/LayoutWrapper";
import Status from "./screens/Status/Status";
import ImportHistory from "./screens/Import/ImportHistory";
import AppEditor from "./screens/ChannelManagement/AppEditor/AppEditor";
import Freight from "./screens/Settings/Freight/Freight.jsx";
import NewFreight from "./screens/Settings/Freight/components/NewFreight";
import Dealers from "./screens/Dealers/Dealers";
import NewDealers from "./screens/Dealers/NewDealers.tsx";
import Matcher from "./screens/Catalog/Matcher/Matcher";
import MatcherHistory from "./screens/Catalog/Matcher/components/MatcherHistory";
import Financial from "./screens/Financial/index";
import ProductManagement from "./screens/Catalog/ProductManagement/ProductManagement.jsx";
import { roleAccessFinancial } from "./infrastructure/utils/RoleAccessFinancial";
import { i18n } from "./translate/i18n";
import PaymentConfigurations from "./screens/PaymentConfigurations/PaymentConfigurations";
import RetailerConfigurations from "./screens/Settings/RetailerConfigurations/RetailerConfigurations";
import ExchangeAndReturn from "./screens/ExchangeAndReturn/ExchangeAndReturn";
import { erRoleAccess } from "./screens/ExchangeAndReturn/ERRoleAccess";

//Admin = 1                     //NeomodeAdmin
//Retailer = 2                  //Varejista
//Seller = 3                    //Lojista
//Marketing = 4                 //Marketing
//SalesManagement = 5           //Gestão de vendas (somente leitura)
//SalesManagementComplete = 6   //Gestão de vendas (acesso completo)
//Marketplace = 7               //Marketplace
//MarketplaceRetailer = 8       //Varejista de shopping

function RoleAccess(roles, component) {
	if (
		roles.findIndex((element) => element == localStorage.getItem("role")) != -1
	) {
		return component;
	} else {
		if (
			localStorage.getItem("token") != null ||
			localStorage.getItem("token") != undefined ||
			localStorage.getItem("token") != ""
		) {
			return Error;
		} else {
			window.location.href = "/login";
		}
	}
}

export const openRoutes = [
	{ path: "/login/:route", name: "Login", Component: IndexLogin },
	{ path: "/login", name: "Login", Component: IndexLogin },
	{ path: "/reset-password", name: "Reset Password", Component: ResetPassword },
	{ path: "/ui-kit", name: "UI Kit ", Layout: LayoutWrapper, Component: Uikit },
];

export const closedRoutes = [
	{
		path: "/",
		name: <i className="material-icons">home</i>,
		Layout: LayoutWrapper,
		Component: RoleAccess([1, 2, 3, 4, 5, 6, 7, 8], Dashboard),
	},
	{
		path: "/dashboard",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Dashboard
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([1, 2, 3, 4, 5, 6, 7, 8], Dashboard),
	},

	{
		path: "/catalog/management/categories",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Categorias
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], Categories),
	},

	{
		path: "/products",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], Products),
	},
	{
		path: "/products/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Produtos
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], Products),
	},
	{
		path: "/products/:page/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Novo Produto
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], NewProduct),
	},
	{
		path: "/products/:page/edit/:productId",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Produto
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], NewProduct),
	},
	{
		path: "/products/:page/edit/:productId/sku/:skuId",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Variação
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], NewSku),
	},

	{
		path: "/catalog/management/inventory",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 3, 8], ProductManagement),
	},
	{
		path: "/catalog/management/inventory/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Estoques
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 3, 8], ProductManagement),
	},

	{
		path: "/sku",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], Skus),
	},
	{
		path: "/sku/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				SKUs
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], Skus),
	},
	{
		path: "/sku/:page/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Novo SKU
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], NewSkus),
	},
	{
		path: "/sku/:page/edit/:skuId",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar SKU
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], NewSkus),
	},

	{
		path: "/orders",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess(
			[2, 3, 7, 8],
			localStorage.getItem("useStateMachine") === "true" ? SMOrders : Orders
		),
	},
	{
		path: "/orders/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Pedidos
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess(
			[2, 3, 7, 8],
			localStorage.getItem("useStateMachine") === "true" ? SMOrders : Orders
		),
	},
	{
		path: "/orders/:page/detail/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Detalhes do pedido
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess(
			[2, 3, 7, 8],
			localStorage.getItem("useStateMachine") === "true"
				? SMDetailsOrders
				: DetailsOrders
		),
	},
	{
		path: "/exchangeandreturn",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess(erRoleAccess(), ExchangeAndReturn),
	},
	{
		path: "/exchangeandreturn/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				{i18n.t("erList.DisplayName")}
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess(erRoleAccess(), ExchangeAndReturn),
	},
	{
		path: "/exchangeandreturn/:page/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				{i18n.t("erDetails.DisplayName")}
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess(erRoleAccess(), ExchangeAndReturn),
	},
	{
		path: "/exchangeandreturn/:page/:id/history",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				{i18n.t("erDetails.History")}
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess(erRoleAccess(), ExchangeAndReturn),
	},
	{
		path: "/matcher",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Mapeamento de Catalogo
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], Matcher),
	},
	{
		path: "/matcher/history/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Histórico de Mapeamento
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 3, 7, 8], MatcherHistory),
	},
	{
		path: "/collections",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7], Collections),
	},
	{
		path: "/collections/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Coleções
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7], Collections),
	},
	{
		path: "/collections/:page/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Nova Coleção
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7], NewCollection),
	},
	{
		path: "/collections/:page/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Coleção
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7], NewCollection),
	},

	{
		path: "/stores",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], StoresPanel),
	},
	{
		path: "/stores/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Lojas
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], StoresPanel),
	},
	{
		path: "/stores/:page/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Nova Loja
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], NewStoresPanel),
	},

	{
		path: "/stores/:page/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Loja
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 8], NewStoresPanel),
	},

	{
		path: "/salespeople",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 3], Dealers),
	},
	{
		path: "/salespeople/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Vendedores
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 3], Dealers),
	},
	{
		path: "/salespeople/:page/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Novo Vendedor
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 3], NewDealers),
	},
	{
		path: "/salespeople/:page/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Vendedor
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7, 3], NewDealers),
	},
	{
		path: "/settings/payment",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				{i18n.t("configurations.Payment")}
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2], PaymentConfigurations),
	},
	{
		path: "/settings/payment/:screen",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				{i18n.t("paymentConfig.CommercialConditions")}
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2], PaymentConfigurations),
	},
	{
		path: "/settings/payment/:screen/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				{i18n.t("configurations.Create")}
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2], PaymentConfigurations),
	},
	{
		path: "/settings/payment/:screen/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				{i18n.t("configurations.Edit")}
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2], PaymentConfigurations),
	},
	{
		path: "/settings/retailerconfiguration",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				{i18n.t("configurations.RetailerConfigurations")}
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7], RetailerConfigurations),
	},
	{
		path: "/settings/retailerconfiguration/:screen",
		name: <></>,
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7], RetailerConfigurations),
	},
	{
		path: "/retailerconfigurations",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Configurações dos varejistas
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([1], Retailers),
	},
	{
		path: "/retailerconfigurations/new",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Novo Varejistas
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([1], NewRetailers),
	},
	{
		path: "/retailerconfigurations/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Configurações do Varejista
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([1], NewRetailers),
	},

	{
		path: "/ui-kit",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				UI Kit
			</span>
		),
		Layout: LayoutWrapper,
		Component: Uikit,
	},

	{
		path: "/channel-management/app-editor",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Gestão do Canal
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7], AppEditor),
	},
	{
		path: "/channel-management/app-editor",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				App Editor
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 7], AppEditor),
	},
	// {
	// 	path: "/channel-management/app-editor/new-questionnaire",
	// 	name: (
	// 		<span className="link-breadcrumb">
	// 			<i className="material-icons ml-2">keyboard_arrow_right</i>
	// 			Novo Questionário
	// 		</span>
	// 	),
	// 	Layout: Painel,
	// 	Component: RoleAccess([2, 7], NewQuestionnaire),
	// },

	{
		path: "/channel-management/:type/:screen",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Gestao de Canal
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], ChannelManagement),
	},
	{
		path: "/channel-management/:type/:screen/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Banner
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], ChannelManagement),
	},

	{
		path: "/vouchers",
		name: "",
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], Vouchers),
	},
	{
		path: "/vouchers/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons">keyboard_arrow_right</i>
				Cupons
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], Vouchers),
	},
	{
		path: "/vouchers/:page/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Novo Cupom
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], NewVoucher),
	},
	{
		path: "/vouchers/:page/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Cupom
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], NewVoucher),
	},

	{
		path: "/promotions",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Promoções
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], Promotions),
	},
	{
		path: "/promotions/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Nova Promoção
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], NewPromotions),
	},
	{
		path: "/promotions/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editar Promoção
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 4, 7], NewPromotions),
	},

	{
		path: "/import",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Importação
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 3, 7, 8], Import),
	},
	//{ path: "/import/history", name: "Todas Importações", Layout: Painel, Component: RoleAccess([2, 3, 7, 8], ImportHistory) },
	{
		path: "/import/history/:page",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Histórico de Importação
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 3, 7, 8], ImportHistory),
	},

	// Both /export links below are commented because the screen is not finished
	// {
	// 	path: "/export",
	// 	name: (
	// 		<span className="link-breadcrumb">
	// 			<i className="material-icons ml-3">keyboard_arrow_right</i>
	// 			Exportação
	// 		</span>
	// 	),
	// 	Layout: Painel,
	// 	Component: RoleAccess([2, 3, 7, 8], Export),
	// },
	// {
	// 	path: "/export/:page",
	// 	name: (
	// 		<span className="link-breadcrumb">
	// 			<i className="material-icons ml-3">keyboard_arrow_right</i>
	// 			Exportação
	// 		</span>
	// 	),
	// 	Layout: Painel,
	// 	Component: RoleAccess([2, 3, 7, 8], Export),
	// },

	{
		path: "/setup/welcome",
		name: "Welcome",
		Component: RoleAccess([2], Welcome),
	},
	{
		path: "/setup/onboarding/:step",
		name: "Onboarding",
		Component: RoleAccess([2], Onboarding),
	},
	{
		path: "/setup/brand/:step",
		name: "Brand",
		Component: RoleAccess([2], Brand),
	},
	{ path: "/setup/stores", name: "Stores", Component: RoleAccess([2], Stores) },
	{
		path: "/setup/stores/:page",
		name: "Stores",
		Component: RoleAccess([2], Stores),
	},
	{
		path: "/setup/stores/:page/new",
		name: "Stores",
		Component: RoleAccess([2], NewStoresPanel),
	},
	{
		path: "/setup/stores/:page/edit/:id",
		name: "Stores",
		Component: RoleAccess([2], NewStoresPanel),
	},
	// { path: "/setup/store/new", name: "Nova Loja", Component: RoleAccess([2], NewStore) },
	// { path: "/setup/store/edit/:id", name: "Editar Loja", Component: RoleAccess([2], NewStore) },
	// { path: "/setup/store/edit/:id", name: "Editar Loja", Component: RoleAccess([2], NewStore) },
	{
		path: "/setup/app-store",
		name: "App Store",
		Component: RoleAccess([2], AppStore),
	},
	{
		path: "/setup/content/:step",
		name: "Conteúdo",
		Component: RoleAccess([2], Content),
	},
	{
		path: "/setup/confirm",
		name: "Confirmação",
		Component: RoleAccess([2], Confirm),
	},

	{
		path: "/status",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Status
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([8], Status),
	},

	{
		path: "/freight",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Gestão de Frete
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 3, 7, 8], Freight),
	},
	//{ path: "/freight/:page", name: "Todos Fretes", Layout: Painel, Component: RoleAccess([2, 7, 3], FreightList) },
	{
		path: "/freight/new/",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Adicionar novo frete personalizado
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 3, 7, 8], NewFreight),
	},
	{
		path: "/freight/edit/:id",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-2">keyboard_arrow_right</i>
				Editando frete personalizado
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess([2, 3, 7, 8], NewFreight),
	},
	//Pagar.Me
	{
		path: "/financial/:screen",
		name: (
			<span className="link-breadcrumb">
				<i className="material-icons ml-3">keyboard_arrow_right</i>
				Financeiro
			</span>
		),
		Layout: LayoutWrapper,
		Component: RoleAccess(
			roleAccessFinancial(
				localStorage.getItem("usePagarMeDashboard"),
				localStorage.getItem("usePagarMeDashboardForRetailer"),
				localStorage.getItem("role")
			),
			Financial
		),
	},

	/* { path: "*", name: "404", Component: Error }, */
];
