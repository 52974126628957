import { Box, Divider, Paper, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { TCOrder } from "../../../domain/models/troquecommerce/TCOrder";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import { paletteObject } from "../../../theme/foundations/palette";
import { formatDate } from "../../../infrastructure/utils/FormatMask";

type Props = { data: TCOrder | null; isLoading: boolean };

export default function ERHistory({ data, isLoading }: Props) {
	const renderHistorySteps = (): JSX.Element | JSX.Element[] => {
		if (!data) return <></>;

		const reverseHistory = [...data.history].reverse();
		return reverseHistory.map((log) => (
			<Paper key={log.created_at} sx={{ p: 2 }}>
				<CustomTypography variant="secondaryTitle" isBold>
					{log.description}
				</CustomTypography>
				<Divider sx={{ my: 1 }} />
				<CustomTypography variant="default" color="grey60" sx={{ mb: 0.5 }}>
					Criado por: {log.created_by}
				</CustomTypography>
				<CustomTypography variant="small" color="grey50">
					{formatDate(log.created_at)}
				</CustomTypography>
			</Paper>
		));
	};

	return (
		<Box className="card" p={3} position="relative">
			<CustomTypography variant="title" isBold marginBottom={3}>
				Histórico do pedido
			</CustomTypography>
			{isLoading || !data ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<Stack
					direction="column"
					spacing={3}
					pl={2}
					sx={{ borderLeft: `3px solid ${paletteObject.light.main}` }}
				>
					{renderHistorySteps()}
				</Stack>
			)}
		</Box>
	);
}
